export const requestOptions = {
  post(body) {
    return {
      method: "POST",
      ...headers(),
      body: JSON.stringify({ ...body }),
    };
  },
  get() {
    return {
      method: "GET",
      ...headers(),
    };
  },
};

function headers() {
  const currentUser = JSON.parse(localStorage.getItem("userData")) || {};
  const authHeader = currentUser.token
    ? { Authorization: `Bearer ${currentUser.token}` }
    : {};
  return {
    headers: {
      ...authHeader,
      "Content-Type": "application/json",
    },
  };
}
