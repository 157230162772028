import Vue from "vue";
export function handleResponse(response) {
  return response.text().then((text) => {
    // console.log(response);
    const data = text && JSON.parse(text);

    if ([200, 201].indexOf(response.status) === -1) {
      Vue.prototype.$vs.notification({
        color: "danger",
        position: "top-right",
        duration: "2000",
        // progress: "auto",
        title: data.code,
        text: data.message,
      });
      if ([401, 403].indexOf(response.status) !== -1) {
        localStorage.clear("userData");
        window.location.href = "/login";
      }
    }

    return data;
  });
}
