import {
  ROOT,
  GET_TOKEN,
  GET_DETAIL_PROFILE,
  UPDATE_PROFILE,
} from "@/api/constant";
import { handleResponse } from "@/api/handle-response";
import { requestOptions } from "@/api/request-options";

function login(data) {
  return fetch(ROOT + GET_TOKEN, requestOptions.post(data))
    .then(handleResponse)
    .then((data) => data);
}

function getDetailProfile(id) {
  return fetch(ROOT + GET_DETAIL_PROFILE + "/" + id, requestOptions.get())
    .then(handleResponse)
    .then((data) => data);
}

function updateProfile(id, data) {
  return fetch(ROOT + UPDATE_PROFILE + "/" + id, requestOptions.post(data))
    .then(handleResponse)
    .then((data) => data);
}

export const commonApi = {
  login,
  getDetailProfile,
  updateProfile,
};
