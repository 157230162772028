import { commonApi } from "@/api/common.js";
import { memberApi } from "@/api/member.js";
import EventBus from "@/EventBus";
export default {
  components: {},
  data: () => ({
    fullName: "",
    userName: "",
    passWord: "",
    isLoadingSignUp: false,
  }),
  methods: {
    async signUp() {
      if (!this.fullName || !this.userName || !this.passWord) {
        this.$vs.notification({
          color: "danger",
          position: "top-right",
          duration: "2000",
          title: "Bạn chưa nhập đủ thông tin",
        });
        return;
      }

      this.isLoadingSignUp = true;
      let body = {
        // name: this.fullName,
        role: "customer",
        username: this.userName,
        password: this.passWord,
      };

      await memberApi.createMember(body).then((res) => {
        if (res.id) {
          let login = {
            username: this.userName,
            password: this.passWord,
          };
          commonApi.login(login).then((res) => {
            if (res.statusCode == 200) {
              localStorage.setItem(
                "userData",
                JSON.stringify({
                  ...res.data,
                })
              );

              EventBus.$emit("afterLogin", true);

              if (res.data.roles.includes("customer")) {
                return this.$router.push({
                  name: "memberDashboard",
                });
              } else if (res.data.roles.includes("driver")) {
                return this.$router.push({
                  name: "driverDashboard",
                });
              }
            }
          });
        }
      });

      this.isLoadingSignUp = false;
    },
  },
};
