import { ROOT, GET_LIST_MEMBER, CREATE_MEMBER } from "@/api/constant";
import { handleResponse } from "@/api/handle-response";
import { requestOptions } from "@/api/request-options";

function getListMember(data) {
  return fetch(ROOT + GET_LIST_MEMBER, requestOptions.post(data))
    .then(handleResponse)
    .then((data) => data);
}

function createMember(data) {
  return fetch(ROOT + CREATE_MEMBER, requestOptions.post(data))
    .then(handleResponse)
    .then((data) => data);
}

export const memberApi = {
  getListMember,
  createMember,
};
