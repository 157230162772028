export const ROOT = "https://api.nhaxekelan.com/wp-json/";
//"http://datxe:8888/wp-json/"; //http://192.168.1.7:8888/wp-json/

export const GET_TOKEN = "jwt-auth/v1/token";

export const CREATE_BOOKING = "izxe/booking/create"; //"wp/v2/booking";
export const CANCEL_BOOKING = "izxe/booking/cancel";
export const GET_LIST_BOOKING = "izxe/booking/list";
export const GET_DETAIL_BOOKING = "izxe/booking/detail";
export const UPDATE_BOOKING = "izxe/booking/update";
export const ONCAR_BOOKING = "izxe/booking/oncar";
export const ADD_PAYMENT_BOOKING = "izxe/booking/add-payment";

export const DASHBOARD_MEMBER = "izxe/dashboard/member";
export const DASHBOARD_DRIVER = "izxe/dashboard/driver";

export const GET_LIST_MEMBER = "izxe/member/list";
export const CREATE_MEMBER = "izxe/member/create"; //"wp/v2/users";

export const CREATE_NEW_CAR = "izxe/car/create";
export const GET_LIST_CAR = "izxe/car/list";
export const GET_DETAIL_CAR = "izxe/car/detail";
export const DELETE_CAR = "izxe/car/delete";

export const REPORT_ADVANCED = "izxe/report/advanced";

export const GET_LIST_DEPARTURE_SCHEDULE = "izxe/departure-schedule/list";
export const GET_DETAIL_DEPARTURE_SCHEDULE = "izxe/departure-schedule/detail";
export const UPDATE_STATUS_DEPARTURE_SCHEDULE =
  "izxe/departure-schedule/update-status";
export const ADD_BUY_FUEL = "izxe/departure-schedule/buy-fuel";
export const ADD_PACKAGE = "izxe/departure-schedule/add-package";
export const GET_LIST_PACKAGE = "izxe/departure-schedule/get-list-package";

export const GET_LIST_SMS = "izxe/sms/list";

export const GET_DETAIL_PROFILE = "wp/v2/users";
export const UPDATE_PROFILE = "wp/v2/users";
